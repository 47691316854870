$(document).ready(function() {
	$('.parallax-window').parallax({imageSrc: 'images/CampSite.jpg'});
	$('#Photos').parallax({imageSrc: 'images/JAY_6408.jpg'});
	
	var flip = true;
	var animating = false;


	var stickyTop = $('#header').outerHeight() - $('#nav-inner').outerHeight();
	var stickyBottom = $('#header').outerHeight();
	$(window).resize(function(){
		sticky = $('#header').outerHeight() - $('#nav-inner').outerHeight();
		stickyBottom = $('#header').outerHeight();
	})


	$(window).scroll(function () {
		  //if you hard code, then use console
		  //.log to determine when you want the 
		  //nav bar to stick.  
		 
		
		if ($(window).scrollTop() <= stickyTop) {
			
		
			$('#nav-outer').removeClass('navbar-fixed');
			$('#nav-outer').addClass('navbar-absolute');
			$(".underline").css("display","block");
			
		}
		else{

			if($(window).scrollTop() <= stickyBottom){


				$('#nav-outer').removeClass('navbar-absolute');
				$('#nav-outer').addClass('navbar-fixed');
				$(".underline").css("display","none");


			}



			/*if(flip == true && animating == false){
				animating = true;
			
			$(".navlinks").animate({
				marginTop:"0"
			
			});
			
			$("#nav-outer").animate({
				paddingTop:"10px",
			
			});
			$(".navlinks li").animate({
				margin:"0 5%"
			});
			$( ".navlinks img" ).animate({
					height: "60px",
					}, 700,function(){
						animating = false;
						
			});
			
				
				
			$( "#nav-inner" ).animate({
			paddingLeft: "50px",
			paddingRight: "50px",
			}, 700,function(){
				animating = false;
			});
			$(".underline").css("display","none");
			flip = false;
				
			}*/
			
			
			
		}
		
		
		
		
		});
		
		
		$(".openOverlayNav").click(function(){
			$("#overlayNav").css("height","100%");
			$(".openOverlayNav").toggleClass("change");
		});

		$(".closeOverlayNav").click(function(){
			$("#overlayNav").css("height","0%");
		});

		$(".overlay-content li").click(function(){
			$("#overlayNav").css("height","0%");
		});


		
		
});